<template>
<div v-bind:key="refreshKey">
  <b-card no-body>
    <b-card-header class="pb-50 d-flex">
      <h5 class="d-inline-block">{{ $t('filters.title') }}</h5>
      <div>
        <a id="load-query-bottom" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.load')"><i class="fa-solid fa-upload"></i></a>
        <a id="save-query-bottom" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.save')"><i class="fa-regular fa-floppy-disk"></i></a>
        <a class="d-inline-block" @click="refreshTables" v-b-tooltip :title="$t('operations.refresh')"><i class="fa-solid fa-rotate-right"></i></a>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <label>{{$t('filters.freeSearch')}}</label>
          <b-input-group>
          <b-form-input
              size="sm"
              v-model="filter.searchText"
              class="d-inline-block"
              debounce="1500"
          />
            <template #append>
              <b-button  v-b-toggle="'advanced-search'" variant="outline-primary"  size="sm">
                <i v-if="collapsedSearch === true" class="fa-solid fa-minus"></i>
                <i  v-else class="fa-solid fa-plus"></i>
                {{$t('filters.advanced')}}
              </b-button>
            </template>
          </b-input-group>
          <div  v-if="$refs.lastUpdatePicker != null && collapsedSearch !== true">
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="success" v-if="filter.defendantSearch != null && filter.defendantSearch !== ''">{{filter.defendantSearch}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="secondary" v-if="filter.attorneysSearch != null && filter.attorneysSearch !== ''">{{filter.attorneysSearch}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="primary" v-if="$refs.lastUpdatePicker.formattedTimeStr != null">{{$refs.lastUpdatePicker.formattedTimeStr}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="primary" v-if="Array.isArray(filter.section) && filter.section.length > 0">{{filter.section.join(",")}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="primary" v-if="filter.amount.min != null && filter.amount.min !== '' && filter.amount.min > 0">{{$t('filters.amount.min')}}: {{filter.amount.min}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="warning" v-if="filter.sentenceOnly === true">{{$t('filters.sentenceOnly')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="warning" v-if="filter.sentenceComplianceOnly === true">{{$t('filters.sentenceComplianceOnly')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="warning" v-if="filter.art513Or523Only === true">{{$t('filters.art513Or523Only')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="info" v-if="filter.points.defendants.min != null && filter.points.defendants.min !== '' && filter.points.defendants.min > 0">{{$t('filters.defendant')}}: {{filter.points.defendants.min}} {{$t('filters.points.points')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="info" v-if="filter.points.amount.min != null && filter.points.amount.min !== '' && filter.points.amount.min > 0">{{$t('filters.amount.title')}}: {{filter.points.amount.min}} {{$t('filters.points.points')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="info" v-if="filter.points.keyWords.min != null && filter.points.keyWords.min !== '' && filter.points.keyWords.min > 0">{{$t('filters.keyWord')}}: {{filter.points.keyWords.min}} {{$t('filters.points.points')}}</b-badge>
            <b-badge class="mr-1" style="margin-top: 5px" pill variant="info" v-if="filter.points.section.min != null && filter.points.section.min !== '' && filter.points.section.min > 0">{{$t('filters.section')}}: {{filter.points.section.min}} {{$t('filters.points.points')}}</b-badge>
          </div>
        </b-col>
      </b-row>
      <b-collapse v-model="collapsedSearch" class="mt-2" id="advanced-search">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.lastUpdate')}}</label>
            <calendar-picker-range ref="lastUpdatePicker" size="sm" v-model="filter.lastUpdate"/>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.id')}}</label>
            <b-form-input size="sm" v-model="filter.id" debounce="1500" class="d-inline-block"/>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.complainant')}}</label>
            <b-form-input size="sm" v-model="filter.complainantSearch" debounce="1500" class="d-inline-block"/>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.defendant')}}</label>
            <b-form-input size="sm" v-model="filter.defendantSearch" debounce="1500" class="d-inline-block"/>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.attorneys')}}</label>
            <b-form-input size="sm" v-model="filter.attorneySearch" debounce="1500" class="d-inline-block"/>
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.sources')}}</label>
            <v-select size="sm"
                      v-model="filter.sources"
                      :options="allSources"
                      taggable
                      class="small"
                      :clearable="true"
                      multiple
            />
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.summaries')}}</label>
            <v-select size="sm"
                      v-model="filter.summaries"
                      :options="allSummaries"
                      taggable
                      class="small"
                      :clearable="true"
                      multiple
            />
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.section')}}</label>
            <v-select
                taggable
                class="small"
                v-model="filter.section"
                :options="allSections"
                label="section"
                :reduce="(status) => status.section"
                :clearable="true"
                multiple
            />
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.subsection')}}</label>
            <v-select class="small"
                taggable
                v-model="filter.subsection"
                :options="subsectionsBySectionFilter"
                :clearable="true"
                multiple
            />
          </b-col>
          <b-col cols="12" md="4" lg="3">
            <label>{{$t('filters.amount.min')}}</label>
            <b-form-input
                debounce="1500"
                number
                size="sm"
                v-model="filter.amount.min"
                class="d-inline-block"
            />
          </b-col>
          <b-col cols="12" md="12" lg="8" >
            <b-form-checkbox class="d-inline-block pr-2" style="margin-top: 30px" v-model="filter.sentenceOnly" switch>
              {{$t('filters.sentenceOnly')}}
            </b-form-checkbox>
            <b-form-checkbox class="d-inline-block" style="margin-top: 30px" v-model="filter.sentenceComplianceOnly" switch>
              {{$t('filters.sentenceComplianceOnly')}}
            </b-form-checkbox>
            <b-form-checkbox class="d-inline-block" style="margin-top: 30px;margin-left: 6px" v-model="filter.art513Only" switch>
              {{$t('filters.art513Only')}}
            </b-form-checkbox>
            <b-form-checkbox class="d-inline-block" style="margin-top: 30px;margin-left: 6px" v-model="filter.art523Only" switch>
              {{$t('filters.art523Only')}}
            </b-form-checkbox>
          </b-col>
        </b-row>
      <h5 class="mt-1 mb-2">{{$t('filters.pointsTitle')}}</h5>

      <b-row>
        <b-col cols="12" md="2">
          <div class="d-flex align-content-center ">
            <label class="d-inline-block pr-1" style="white-space: nowrap; margin-top: 5px">{{ $t('filters.section') }}:</label>
            <b-form-input size="sm"  v-model="filter.points.section.min" debounce="1500" class="d-inline-block"/>
          </div>
        </b-col>
        <b-col cols="12" md="2">
          <div class="d-flex">
            <label class="d-inline-block pr-1" style="white-space: nowrap; margin-top: 5px">{{ $t('filters.defendant') }}</label>
            <b-form-input size="sm" v-model="filter.points.defendant.min" class="d-inline-block" debounce="1500"/>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          <div class="d-flex">
            <label class="d-inline-block pr-1" style="white-space: nowrap; margin-top: 5px">{{ $t('filters.keyWord') }}</label>
            <b-form-input size="sm" v-model="filter.points.keyWords.min" class="d-inline-block" debounce="1500"/>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          <div class="d-flex">
            <label class="d-inline-block pr-1" style="white-space: nowrap; margin-top: 5px">{{ $t('filters.amountPoint') }}</label>
            <b-form-input  size="sm" v-model="filter.points.amount.min" class="d-inline-block" debounce="1500"/>
          </div>
        </b-col>
        <b-col cols="12" md="2">
          <div class="d-flex">
            <label class="d-inline-block pr-1" style="white-space: nowrap; margin-top: 5px">{{ $t('filters.points.total') }}</label>
            <b-form-input  size="sm" v-model="filter.points.total.min" class="d-inline-block" debounce="1500"/>
          </div>
        </b-col>
      </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
  <!-- Save query popover -->
  <b-popover target="save-query-bottom" placement="bottom" triggers="click" @show="closeAllPopovers">
    <div style="width: 350px">
      <p class="text-center text-muted">
        Neste local você pode salvar suas consultas e carregar posteriormente.<br/>
        Basta inserir um nome e clicar em salvar</p>
      <b-input-group>
        <b-form-input v-model="popover.saveQuery.name" size="sm"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-success" @click="saveQuery">{{$t('operations.save')}}</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-popover>

  <b-popover target="load-query-bottom" placement="bottom" triggers="click" @show="closeAllPopovers" @shown="loadAllQueries">
    <div class="position-relative" style="width: 360px">
      <p class="text-center text-muted">
        Neste local você pode carregar ou excluir suas consultas salvas.</p>
      <div>
        <v-select
            v-model="popover.loadQuery.selectedQueryIndex"
            :clearable="false"
            label="name"
            :options="popover.loadQuery.queries"
            :reduce="option => option.index"
        />
      </div>

      <b-row no-gutters style="width: 100%; margin-top: 5px">
        <b-col cols="6">
          <b-button class="d-inline-block" size="sm" variant="outline-danger" @click="deleteQuery" :disabled="popover.loadQuery.selectedQueryIndex === null">{{$t('operations.delete')}}</b-button>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button class="d-inline-block" size="sm" variant="outline-success" @click="loadQuery" :disabled="popover.loadQuery.selectedQueryIndex === null">{{$t('operations.load')}}</b-button>
        </b-col>
      </b-row>
    </div>
  </b-popover>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CalendarPickerRange from '@/components/widget/picker/calendarPickerRange'
import mixinBase from '@/components/mixin/mixinBase'
import { parseJson, removeEmpty } from '@/model/Utils'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinPopover from '@/components/mixin/mixinPopover'

import SuitFilter from '@/model/SuitFilterRequest'

export default {
  name: 'SuitsFilterCard',
  components: { CalendarPickerRange },
  mixins: [mixinBase, mixinNotifications, mixinPopover],
  data: () => ({
    collapsedSearch: true,
    popover:{
      saveQuery: {
        name:"",
        error:""
      },
      loadQuery: {
        queries:[],
        selectedQueryIndex: null,
      }
    }
  }),
  props: ['value', 'sessionKey'],
  watch: {
    value: {
      deep: true,
      handler(v) {
        sessionStorage.setItem(`${this.sessionKey}-filters`, JSON.stringify(v))
      }
    }
  },
  computed: {
    ...mapGetters('suits', ['allSections', 'allSources' , 'allSummaries']),
    filter: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    subsectionsBySectionFilter() {
      if (this.filter.section == null || this.filter.section.length <= 0) {
        return []
        /* return this.allSections
             .filter(s => s.subsections != null)
             .flatMap(s => s.subsections)
             .map(s => s.name)
             .filter(s => s != null)*/
      }
      let sectionsFiltered = this.filter.section
      return this.allSections
          .filter(s => s.subsections != null && sectionsFiltered.includes(s.section))
          .flatMap(s => s.subsections)
          .map(s => s.name)
          .filter(s => s != null)
    },
  },
  created() {
    this.initCache()
    this.$emit('loaded', true)
  },
  methods: {
    initCache() {
      let $this = this
      let filter = parseJson(sessionStorage.getItem(`${this.sessionKey}-filters`), this.filter, true)
      if(this.sessionKey === "all") {
        filter.lastUpdate = {
          start: $this.$moment()
              .startOf('month')
              .format('YYYY-MM-DD'),
          end: $this.$moment()
              .format('YYYY-MM-DD')
        }
      }
      this.filter = filter
    },
    refreshTables() {
      this.$root.$emit('system::refresh-tables')
    },
    //Save query popover
    saveQuery() {
      let filter = {...this.filter};
      delete filter.lastUpdate
      let queries = parseJson(localStorage.getItem('suit-queries'), [])
      queries.push({ name: this.popover.saveQuery.name, query: filter })
      localStorage.setItem('suit-queries', JSON.stringify(queries))
      this.popover.saveQuery.name = ''
      this.popover.saveQuery.error = ''
      this.$root.$emit('bv::hide::popover')
      this.showSuccessNotification(this.$t('filters.saveQuerySystem.querySaved'))
    },
    loadAllQueries(){
      let queries = parseJson(localStorage.getItem('suit-queries'), [])
      queries = queries.map((q, index) => Object.assign(q, {index}))
      this.popover.loadQuery.queries = queries
    },
    loadQuery(){
      if(this.popover.loadQuery.selectedQueryIndex == null || this.popover.loadQuery.selectedQueryIndex < 0) return
      let lastUpdate = this.filter.lastUpdate
      let query = this.popover.loadQuery.queries[this.popover.loadQuery.selectedQueryIndex].query
      this.filter = {...SuitFilter(), ...query, lastUpdate}
      this.forceUpdate();
      this.$root.$emit('bv::hide::popover')
    },
    deleteQuery(){
      if(this.popover.loadQuery.selectedQueryIndex == null || this.popover.loadQuery.selectedQueryIndex < 0) return
      let queries = this.popover.loadQuery.queries
      queries.splice(this.popover.loadQuery.selectedQueryIndex, 1)
      localStorage.setItem('suit-queries', JSON.stringify(queries))
      this.popover.loadQuery.selectedQueryIndex = null
      this.$root.$emit('bv::hide::popover')
      this.showSuccessNotification(this.$t('filters.saveQuerySystem.queryDeleted'))
    },
  }
}
</script>

<style scoped>

</style>
