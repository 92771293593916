<template>
<div>
  <b-row>
    <b-col cols="12">
      <h5>{{$t('suits')}}</h5>
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.id')}}</label>
      <!-- Using components -->
      <b-input-group>
        <b-form-input v-bind:value="suit.id" readonly />
        <b-input-group-append>
<!--          <a v-bind:href="`https://esaj.tjsp.jus.br/pastadigital/abrirPastaProcessoDigital.do?instanciaProcesso=pg&cdForo=3&nuProcesso=${suit.id}`"
             class="btn btn-outline-warning" target="_blank"
             v-b-tooltip title="Abrir Pasta Processo Digital"
          >
            <i class="fa-regular fa-folder-open"></i>
          </a>-->
          <a v-bind:href="`https://esaj.tjsp.jus.br/cpopg/search.do?conversationId=&cbPesquisa=NUMPROC&numeroDigitoAnoUnificado=&foroNumeroUnificado=&dadosConsulta.valorConsultaNuUnificado=&dadosConsulta.valorConsultaNuUnificado=UNIFICADO&dadosConsulta.valorConsulta=${suit.id}&dadosConsulta.tipoNuProcesso=SAJ`"
             class="btn btn-outline-success" target="_blank"
             v-b-tooltip title="Procurar no TJ"
             @click="onExternalAccessClicked"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
        </b-input-group-append>
      </b-input-group>
      <small tabindex="-1" class="form-text text-muted text-capitalize" v-if="suit.parentId != null">{{$t('suitDetails.mainSuit')}}: <b-link @click="onParentSelect">{{suit.parentId}}</b-link></small>
    </b-col>
    <b-col cols="4">
      <label>Seção</label>
      <b-form-input v-bind:value="suit.section" readonly />
      <small tabindex="-1" class="form-text text-muted text-capitalize" v-if="suit.parentFound">{{$t('suitDetails.mainSuit')}}: {{suit.parentSection}}</small>
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.subsection')}}</label>
      <b-form-input v-bind:value="suit.subsection" readonly />
      <small tabindex="-1" class="form-text text-muted text-capitalize" v-if="suit.parentId != null && suit.parentSubsection !== suit.subsection">{{$t('suitDetails.mainSuit')}}: {{suit.parentSubsection}}</small>
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.lastUpdate')}}</label>
      <b-form-input v-bind:value="suit.lastUpdate" readonly />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.complainant')}}</label>
      <b-form-input v-bind:value="suit.complainant" v-bind:readonly="!isMonitored" />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.defendant')}}</label>
      <b-form-input v-bind:value="suit.defendant" v-bind:readonly="!isMonitored" />
    </b-col>

  </b-row>
  <b-row class="mt-2">
    <b-col cols="12">
      <h5>{{$t('suitDetails.sentence')}}</h5>
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.sentence')}}</label>
      <b-form-input v-bind:value="$t(`sentences.${suit.sentence}.title`)" v-bind:readonly="!isMonitored" />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.sentenceEstimatedAmount')}}</label>
      <b-form-input v-bind:value="suit.sentenceAmount" v-bind:readonly="!isMonitored" />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.otherSentenceEstimatedAmount')}}</label>
      <b-form-input v-bind:value="(suit.sentenceAmountText || '').split('|').join(' ')" v-bind:readonly="!isMonitored" />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.keyWords')}}</label>
      <b-form-input v-bind:value="(suit.keyWords || []).join(',')" readonly />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.source')}}</label>
      <b-form-input v-bind:value="suit.source" readonly />
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.summary')}}</label>
      <b-form-input v-bind:value="suit.summary" readonly />
    </b-col>

  </b-row>
  <b-row class="mt-2" v-if="suit.suitPointCalculation != null">
    <b-col cols="12">
      <h5>{{$t('suitDetails.points')}}</h5>
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.defendant')}}</label>
      <b-form-input v-bind:value="suit.suitPointCalculation.defendantsPoints" readonly />
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.keyWords')}}</label>
      <b-form-input v-bind:value="suit.suitPointCalculation.keyWordsPoints" readonly />
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.section')}}</label>
      <b-form-input v-bind:value="suit.suitPointCalculation.sectionPoints" readonly />
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.amount')}}</label>
      <b-form-input v-bind:value="suit.suitPointCalculation.amountPoints" readonly />
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.sentence')}}</label>
      <b-form-input v-bind:value="suit.suitPointCalculation.sentencePoints" readonly />
    </b-col>
    <b-col cols="2">
      <label>{{$t('suitDetails.points')}}</label>
      <b-form-input v-bind:value="suit.points" v-bind:readonly="!isMonitored" />
    </b-col>
  </b-row>
  <b-row class="mt-2" v-if="isMonitored">
    <b-col cols="12">
      <h5>{{$t('suitDetails.monitoring')}}</h5>
    </b-col>
    <b-col cols="4">
      <label>{{$t('suitDetails.state')}}</label>
      <v-select
          v-model="suit.monitoring.status"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          label="title"
          :options="monitoringOptionsByStep"
          :reduce="option => option.value"
      />
    </b-col>
    <b-col cols="8">
      <label>{{$t('suitDetails.note')}}</label>
      <b-form-input v-bind:v-model="suit.monitoring.note" v-bind:readonly="!isMonitored" />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'suitDetails',
  props:['value'],
  computed:{
    monitoringOptions(){
      let $this = this;
      return [
        { title: $this.$t('monitoringStatuses.MONITORING.title'), value: 'MONITORING', step:"WATCH" },
        { title: $this.$t('monitoringStatuses.APPROACHING.title'), value: 'APPROACHING', step:"INTERACTION" },
        { title: $this.$t('monitoringStatuses.POSSIBLE_APPROACHING.title'), value: 'POSSIBLE_APPROACHING', step:"INTERACTION" },
        { title: $this.$t('monitoringStatuses.IN_PROGRESS.title'), value: 'IN_PROGRESS', step:"MANAGED" },
        { title: $this.$t('monitoringStatuses.REJECTED.title'), value: 'REJECTED', step:"CONCLUSION" },
        { title: $this.$t('monitoringStatuses.APPROVED.title'), value: 'APPROVED', step:"CONCLUSION" },
        { title: $this.$t('monitoringStatuses.CLOSED.title'), value: 'CLOSED', step:"ENDING" },
        { title: $this.$t('monitoringStatuses.PURCHASE.title'), value: 'CLOSED', step:"PURCHASE" },
        { title: $this.$t('monitoringStatuses.FORUM.title'), value: 'FORUM', step:"ENDING" },
        { title: $this.$t('monitoringStatuses.COMPLETED.title'), value: 'COMPLETED', step:"ENDING" },
        { title: $this.$t('monitoringStatuses.CANCELED.title'), value: 'CANCELED', step:"ENDING" },
        { title: $this.$t('monitoringStatuses.REMOVED.title'), value: 'REMOVED', step:"REMOVED" },
      ]
    },
    monitoringOptionsByStep(){
      let $this = this;
      if(this.suit.monitoring.step == null || this.suit.monitoring.step === "NONE") return [];
      return this.monitoringOptions.filter(o => o.step === $this.suit.monitoring.step);
    },
    suit:{
      get(){return this.value},
      set(v){this.$emit('input',v)},
    },
    isMonitored(){
      return this.suit.monitoring != null && this.suit.monitoring.status != null;
    },

  },
  methods: {
    ...mapActions('suits', ['logExternalAccess']),
    onExternalAccessClicked() {
      this.logExternalAccess(this.suit.id)
    },
    onParentSelect(){
      this.$emit('parent-select',this.suit.parentId);
    },
  }
}
</script>

<style scoped>

</style>
