export default function () {
  return {
    complainantSearch: '',
    defendantSearch: '',
    attorneySearch: '',
    sentenceOnly: false,
    sentenceComplianceOnly: false,
    art513Only: false,
    art523Only: false,
    section: [],
    subsection: [],
    lastUpdate: {
      start: null,
      end: null
    },
    amount: {
      min: null,
      max: null
    },
    points: {
      defendant: {
        min: null,
        max: null
      },
      keyWords: {
        min: null,
        max: null
      },
      section: {
        min: null,
        max: null
      },
      amount: {
        min: null,
        max: null
      },
      total: {
        min: 50,
        max: 100
      },
    },
  }
}
